// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Impressum',
  slug: '/impressum',
  abstract:
    'A Dream – Aims and Ideals of Auroville is published by Prisma Books. Since 1988 Prisma Books of Auroville publishes good old-fashioned, beautifully curated books. Prisma Books offers full publishing services in design, editorial, printing, artwork, illustrations, photography, digital media, exhibition and curatorship.',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Impressum', slug: '/impressum' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     launch: file(relativePath: { eq: "launch.jpg" }) {
//       ...max900
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
    <div
      className="container"
      style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
    >
      <h1>Impressum</h1>
      <ul>
        <li>Compiled by Franz Fassbender</li>
        <li>First edition 2021</li>
        <li>ISBN: 978-81-939500-4-3</li>
        <li>
          Designed & Published by{' '}
          <Link to="https://www.prisma.haus/en/">Prisma</Link>
        </li>
        <li>
          Publisher's address:{' '}
          <Link to="https://goo.gl/maps/nxuUpusYneNUgqGa8">
            Aurelec / Prayogashala, Auroville 605101 Tamil Nadu, India
          </Link>
        </li>
        <li>
          Publisher's email:{' '}
          <Link to="prisma@auroville.org.in">prisma@auroville.org.in</Link>
        </li>
        <li>
          Publisher's website:{' '}
          <Link to="https://books.prisma.haus/en/">books.prisma.haus</Link>
        </li>
        <li>
          Web Book handcrafted by{' '}
          <Link to="https://design.prisma.haus/en/">Prisma Design</Link>
        </li>
      </ul>
      <br />
      <h2 className="as-h3">Photograph credits</h2>
      <p>
        Auroville Archives, Anita Reichle, Indra Poddar Dominique Darr, John
        Mandeen Giorgio Molinari, Franz Fassbender
      </p>
      <p>Cover photo by OM from Auroville</p>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
